<template>
  <AppPanel subtitle="Listagem de perguntas">
    <template #content>
      <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/perguntas/new"
        editPath="/perguntas/"
        :expander="false"
        subtitle="Pergunta"
        :extraActionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        tooltip="Cadastro Pergunta"
        nomeTelaDoManual="perguntas-list"
        origemHistoricoAcao="/pergunta"
       >
        <template #columns>
          <Column field="descricao" header="Descrição" :sortable="true" sortField="descricao"></Column>
          <Column field="tipo" header="Tipo" :sortable="true" sortField="tipo">
              <template #body="slotProps">
                  {{
                    slotProps.data.tipo === 'CB' ? "Combo" :
                    slotProps.data.tipo === 'ME' ? "Múltipla Escolha" :
                    slotProps.data.tipo === 'DI' ? "Dissertativa" :
                    slotProps.data.tipo === 'IN' ? "Inteiro" :
                    ""}}
              </template>
          </Column>
          <Column field="opcoes" header="Opções" :sortable="true" sortField="opcoes"></Column>
          <Column field="ordem" header="Ordem" :sortable="true" sortField="ordem"></Column>
        </template>
      </AppPaginatedGrid>
    </template>
  </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
          service: null,
          record: {},
        };
    },
    created() {
        this.service = new BaseService('/pergunta');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
    }
}
</script>
